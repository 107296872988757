var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.overdue
    ? _c(
        "div",
        [
          _vm.grades.length && !_vm.fetching
            ? [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("div", { staticClass: "text-subtitle-1" }, [
                      _vm._v("Grade Table"),
                    ]),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "", color: "primary" },
                        on: { click: _vm.exportGrades },
                      },
                      [
                        _c("v-icon", { staticClass: "mr-1" }, [
                          _vm._v("download"),
                        ]),
                        _vm._v(" Export to xlsx "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-data-table", {
                  staticClass: "grade-table",
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.gradesAndApps,
                    "group-by": "Status",
                    "loading-text": "Loading... Please wait",
                    "disable-pagination": "",
                    "hide-default-footer": "",
                    "show-group-by": false,
                    "sort-by": "handin_long_id",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: `item.handin_long_id`,
                        fn: function (props) {
                          return [
                            _c(
                              "span",
                              {
                                class:
                                  props.item.Status ===
                                  _vm.gradeStatus.CURRENTLY_GRADING
                                    ? "font-weight-bold"
                                    : "",
                              },
                              [_vm._v(_vm._s(props.item.handin_long_id))]
                            ),
                            props.item.Status ===
                            _vm.gradeStatus.CURRENTLY_GRADING
                              ? _c(
                                  "v-chip",
                                  {
                                    staticClass: "mx-2",
                                    attrs: { color: "info", small: "" },
                                  },
                                  [_vm._v("Opened")]
                                )
                              : props.item.Status === _vm.gradeStatus.UNGRADED
                              ? _c(
                                  "span",
                                  { staticClass: "mx-1 grey--text" },
                                  [_c("v-icon", [_vm._v("help")])],
                                  1
                                )
                              : props.item.Status === _vm.gradeStatus.GRADED
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "v-icon",
                                      { staticClass: "mx-1 success--text" },
                                      [_vm._v("done")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: `item.grade`,
                        fn: function (props) {
                          return [
                            _c(
                              "v-edit-dialog",
                              {
                                attrs: { "return-value": props.item.grade },
                                on: {
                                  "update:returnValue": function ($event) {
                                    return _vm.$set(props.item, "grade", $event)
                                  },
                                  "update:return-value": function ($event) {
                                    return _vm.$set(props.item, "grade", $event)
                                  },
                                  save: function ($event) {
                                    return _vm.save(
                                      props.item.hid,
                                      props.item.grade
                                    )
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "input",
                                      fn: function () {
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Edit",
                                              "single-line": "",
                                              counter: "",
                                              outlined: "",
                                            },
                                            model: {
                                              value: props.item.grade,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "grade",
                                                  $$v
                                                )
                                              },
                                              expression: "props.item.grade",
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    class:
                                      props.item.Status ===
                                      _vm.gradeStatus.CURRENTLY_GRADING
                                        ? "font-weight-bold"
                                        : "",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          props.item.grade ||
                                            "Click to Add Grade"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-1",
                                    staticStyle: { opacity: "0.75" },
                                    attrs: { small: "" },
                                  },
                                  [_vm._v("edit")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `item.open_with`,
                        fn: function ({ item }) {
                          return [
                            !(
                              item.Status ===
                                _vm.gradeStatus.CURRENTLY_GRADING &&
                              _vm.$route.name === "app-open"
                            ) && item.open_with.length === 1
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: { text: "", color: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.gotoApp(
                                          item.open_with[0].iid,
                                          item.open_with[0].snid,
                                          item.open_with[0].aid,
                                          item.open_with[0].aoid,
                                          item.handin_long_id,
                                          item.hid
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", { staticClass: "mr-1" }, [
                                      _vm._v("visibility"),
                                    ]),
                                    _vm._v(" Review "),
                                  ],
                                  1
                                )
                              : !(
                                  item.Status ===
                                    _vm.gradeStatus.CURRENTLY_GRADING &&
                                  _vm.$route.name === "app-open"
                                ) && item.open_with.length > 1
                              ? _c(
                                  "v-menu",
                                  {
                                    attrs: { "offset-y": "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        text: "",
                                                        color: "primary",
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { staticClass: "mr-1" },
                                                    [_vm._v("visibility")]
                                                  ),
                                                  _vm._v(" Review "),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      _vm._l(item.open_with, function (a) {
                                        return _c(
                                          "v-list-item",
                                          {
                                            key: a.aid,
                                            on: {
                                              click: function ($event) {
                                                return _vm.gotoApp(
                                                  a.iid,
                                                  a.snid,
                                                  a.aid,
                                                  a.aoid,
                                                  item.handin_long_id,
                                                  item.hid
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                _vm._s(a.application_long_id)
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ]
            : _vm.fetching
            ? _c("v-skeleton-loader", { attrs: { type: "table" } })
            : _vm._e(),
          _vm.no_handins.length
            ? _c(
                "v-alert",
                {
                  staticClass: "mt-4 mb-0",
                  attrs: { type: "warning", text: "" },
                },
                [
                  _vm._v(" The following instances have no submissions: "),
                  _c(
                    "ul",
                    { staticClass: "mt-2" },
                    _vm._l(_vm.no_handins, function (i) {
                      return _c("li", { key: i }, [
                        _vm._v(" " + _vm._s(i) + " "),
                      ])
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.showNextInstance,
                callback: function ($$v) {
                  _vm.showNextInstance = $$v
                },
                expression: "showNextInstance",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { color: "success" } },
                        [_vm._v("done")]
                      ),
                      _vm._v(" Grade saved "),
                      _c("v-spacer"),
                      _c(
                        "v-icon",
                        {
                          on: {
                            click: function ($event) {
                              _vm.showNextInstance = false
                            },
                          },
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                  _c("v-card-text", { staticClass: "text-center" }, [
                    _vm.nextReviewItem
                      ? _c("div", [
                          _vm._v(" Grade saved, open next ungraded handin? "),
                          _c(
                            "div",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", text: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoNextApp()
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("navigate_next")]),
                                  _vm._v(" Continue "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _c(
                          "div",
                          [
                            _vm._v(
                              " Congratulations, all handins have been graded! "
                            ),
                            _c("p", { staticClass: "mt-2" }, [
                              _vm._v(
                                "To make the results visible to students, edit the assignment configuration and enable handback visibility."
                              ),
                            ]),
                            _c("TheBundleEditDialog", {
                              attrs: {
                                bundleData: _vm.bundleData,
                                highlightHandBack: "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "dialogactivator",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-btn",
                                          { attrs: { color: "primary" } },
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "mr-1" },
                                              [_vm._v("edit")]
                                            ),
                                            _vm._v(" Enable Visibility "),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2213797917
                              ),
                            }),
                          ],
                          1
                        ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      )
    : _c("div", [
        _vm._v(
          "Hand-ins are still possible for this assignment, grading can only be started once the assignment deadline has passed."
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }